import { I18N } from "aurelia-i18n";
import { ConfigService } from "../../resources/services/ConfigService";
import { FhirService } from "../../resources/services/FhirService";
import * as moment from 'moment';
import { UserService } from "../../resources/services/UserService";
import { BasicForm } from "../../resources/elements/BasicForm";
import { ReportService } from "../../resources/services/ReportService";
import { RuntimeInfo } from "../../resources/classes/RuntimeInfo";
import { NitTools } from "resources/classes/NursitTools";
import { bindable } from "aurelia-framework";
import { PatientItem } from "resources/classes/Patient/PatientItem";

export class BudgetsPage {
    public patientId: string;
    protected fhirService: FhirService;
    public items: BudgetItem[];
    public hasItems: boolean = false;

    @bindable startMonth : string;
    @bindable endMonth : string;
    endMonthString : string;     // like 01
    startMonthString : string;  // like 02
    @bindable startYear : string;
    @bindable endYear : string;
    startDateString : string;
    endDateString : string;
    errorMessage : string;
    isValid : boolean = true;
    printType : string = "BUDGET_BOOKS.FRX";

    constructor(protected i18n: I18N) {
        this.fhirService = new FhirService();
    }

    attached() {
        // document.body.classList.add("no-toolbar-window");
    }

    protected detached() {
        // document.body.classList.remove("no-toolbar-window");
    }

    updateDateRange() {
        const getLastDayOfMonth = function (year: number, month: number): string {
            const lastDay = moment(new Date(year, month, 0));
            return lastDay.format('YYYY-MM-DD');
        }

        const getFirstDayOfMonth = function (year: number, month: number): string {
            // Months are zero-indexed (0-11), so we add 1 to the given month.
            const lastDay = moment(new Date(year, month - 1, 1));
            return lastDay.format('YYYY-MM-DD');
        }

        this.startDateString = getFirstDayOfMonth(parseInt(this.startYear), parseInt(this.startMonth));
        this.endDateString = getLastDayOfMonth(parseInt(this.endYear), parseInt(this.endMonth));

        const momentStart = moment(this.startDateString);
        const momentEnd = moment(this.endDateString);

        if (momentEnd.isBefore(momentStart)) {
            this.errorMessage = 'Start darf nicht hinter dem Ende liegen';
            this.isValid = false;
        } else {
            this.isValid = true;
            this.errorMessage = '';
        }
    }

    startMonthChanged() {
        this.updateDateRange();
        this.startMonthString = NitTools.ToString(this.startMonth, 2, '0')
    }
    endMonthChanged() {
        this.updateDateRange();
        this.endMonthString = NitTools.ToString(this.endMonth, 2, '0')
    }
    startYearChanged() {
        this.updateDateRange();
    }
    endYearChanged() {
        this.updateDateRange();
    }

    printButtonClicked() {
        const panel: HTMLDivElement = document.querySelector("#printSettings");
        if (!panel) return;
        panel.style.display = 'block';

        const currentYear = new Date().getFullYear();
        const cbStartYear: HTMLSelectElement = document.querySelector('#startYear');
        const cbStartMonth: HTMLSelectElement = document.querySelector('#startMonth');
        const cbEndYear: HTMLSelectElement = document.querySelector('#endYear');
        const cbEndMonth: HTMLSelectElement = document.querySelector('#endMonth');

        // setup the month
        cbStartMonth.innerHTML = '';
        for (let i = 1; i <= 12; i++) {
            const option = document.createElement('option');
            option.value = String(i);
            option.textContent = String(i);
            cbStartMonth.appendChild(option)
        }

        cbEndMonth.innerHTML = cbStartMonth.innerHTML;
        this.endMonth = this.startMonth = String(new Date().getMonth() + 1);

        // setup the years
        cbStartYear.innerHTML = '';
        for (let i = -4; i <= 0; i++) {
            const year = currentYear + i;
            const option = document.createElement('option');
            option.value = String(year);
            option.textContent = String(year);
            cbStartYear.appendChild(option)
        }

        cbEndYear.innerHTML = cbStartYear.innerHTML;
        this.startYear = this.endYear = String(currentYear);
    }

    abortClicked() {
        const panel: HTMLDivElement = document.querySelector("#printSettings");
        if (!panel) return;
        panel.style.display = 'none';
    }

    startPreviewClicked() {
        this.abortClicked();
        const url = `${ReportService.BaseUrl}/api/Budget/Report/${this.patientId}/${this.printType}?start=${this.startDateString}&end=${this.endDateString}`;
        if (ConfigService.Debug)
            console.warn(`Opening Report in ${url}`);

        window.open(url, '_blank');
    }

    async reloadAll() {
        RuntimeInfo.IsLoading = true;
        try {
            await this.reloadBudgets();
        } catch (err) {
            console.warn(err);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    async activate(params) {
        this.patientId = params?.patientId || PatientItem.LastLoadedPatient?.id;

        BasicForm.pageTitle = "Wirtschaftsbuch";
        if (ConfigService.Debug) window['budgets'] = this;
        await this.reloadBudgets();
    }

    public async reloadBudgets() {
        this.items = [];
        const observations: any[] = await this.fhirService.fetch(`Observation?subject=${this.patientId}&code=financial-record&_sort=date`);
        for (const obs of observations) {
            this.items.push(await BudgetItem.FromObservation(obs, this.fhirService));
        }

        this.hasItems = this.items.length > 0;
    }

    imageWindow: Window;

    showImage(item: BudgetItem) {
        if (!item?.billstrip) return;

        //if (this.imageWindow == null) {
        this.imageWindow = window.open('about:blank', 'ImageWindow');
        //}

        window.setTimeout(() => {
            if (this.imageWindow) {
                this.imageWindow.document.title = item.title + ' - ' + moment(item.created).format(RuntimeInfo.DateTimeFormat);

                const img = this.imageWindow.document.createElement('img');
                img.src = item.billstrip;
                img.style.height = "100%";
                img.style.width = "auto";

                this.imageWindow.document.body.append(img);
                this.imageWindow.focus();
            } else {
                console.warn('Could not open Image Window');
            }
        }, 250);
    }
}

export class BudgetItem {
    public title: string;
    public givenAmount: number;
    public returnedAmount: number;
    public restAmount: number;
    public whenReturned: Date;

    private _spentAmount: number = 0;
    public get spentAmount(): number {
        if (this.isAddItem)
            return Math.abs(this._spentAmount);

        return this._spentAmount;
    }

    public set spentAmount(value: number) {
        this._spentAmount = value;
    }

    public billstrip: string;
    public signatureReturned: string;
    public signatureSpent: string;
    public signatureGiven: string;
    public created: Date;
    public id: string;
    public observation: any;
    public performer: any; // see performer.text.div for display
    public hasItems: boolean = false;
    public hasImage: boolean = false;
    public isCheckItem: boolean = false;
    public isAddItem: boolean = false;
    public isBudgetBook: boolean = false;

    public items: BudgetItem[];

    public static async FromObservation(observation: any, fhirService: FhirService): Promise<BudgetItem> {
        const findByCode = function (code: string) {
            return observation.component.find(o => o.code != null
                && o.code.coding != null
                && typeof o.code.coding.find(c => c.code == code) !== "undefined");
        };

        const getImageString = function (component) {
            if (component && component.valueAttachment) {
                return `data:${component.valueAttachment.contentType};base64,${component.valueAttachment.data}`;
            }

            return undefined;
        };

        const result = new BudgetItem();
        if (!observation || !observation.component)
            return null;

        const titleItem = findByCode("title");
        if (titleItem)
            result.title = titleItem.valueString;

        const givenAmount = findByCode("given-amount");
        if (givenAmount && givenAmount.valueQuantity) {
            result.givenAmount = givenAmount.valueQuantity.value;
        }

        const returnedAmount = findByCode("returned-amount");
        if (returnedAmount && returnedAmount.valueQuantity) {
            result.returnedAmount = returnedAmount.valueQuantity.value;
        }

        const restAmount = findByCode("rest-amount");
        if (restAmount && restAmount.valueQuantity) {
            result.restAmount = restAmount.valueQuantity.value;
        }

        const spentAmount = findByCode("spent-amount");
        if (spentAmount && spentAmount.valueQuantity) {
            result.spentAmount = spentAmount.valueQuantity.value;
        }

        const returnedDate = findByCode("returned-date");
        if (returnedDate && returnedDate.valueDateTime) {
            result.whenReturned = moment(returnedDate.valueDateTime).toDate();
        }

        const billstrip = findByCode("billstrip");
        result.billstrip = getImageString(billstrip);

        const signatureReturned = findByCode("returned-signature");
        result.signatureReturned = getImageString(signatureReturned);

        const signatureSpent = findByCode("spent-signature");
        result.signatureSpent = getImageString(signatureSpent);

        const signatureGiven = findByCode("given-signature");
        result.signatureGiven = getImageString(signatureGiven);

        if (observation.effectiveDateTime) {
            result.created = moment(observation.issued).toDate();
        }

        result.id = observation.id;
        result.observation = observation;

        if (observation.performer && observation.performer[0])
            result.performer = await UserService.GetPractitioner(observation.performer[0].reference, fhirService);

        if (observation.contained) {
            result.items = [];
            for (const obs of observation.contained.filter(o => o.resourceType === "Observation")) {
                result.items.push(await BudgetItem.FromObservation(<any>obs, fhirService));
            }
        }

        result.hasItems = result.items != null && result.items.length > 0;

        if (result.spentAmount < 0 && !result.hasItems) {
            result.title = "Kapitalerhöhung um ";
            result.isAddItem = true;
        }

        const checkAmount = findByCode("balancecheck-amount");
        if (checkAmount?.valueQuantity?.code === "EUR") {
            result.spentAmount = checkAmount?.valueQuantity.value;
            result.isCheckItem = true;
            result.title = `Bestätigungseintrag über `;
        } else {
            result.isCheckItem = false;
        }

        if (result.hasItems) {
            result.items = result.items.sort((a1, a2) => {
                const s1 = JSON.stringify(a1?.created);
                const s2 = JSON.stringify(a2?.created);

                return s1.localeCompare(s2);
            });
        }

        result.isBudgetBook = !!result.observation.extension?.find(o => o.url?.endsWith('/budgets-book'));
        if (result.isBudgetBook)
            result.title = "Wirtschaftsbuch";

        return result;
    }
}
